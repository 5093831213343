import { ref } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';
import { Classroom } from '~/src/domain/entities/schema';

export const createDefaultClassroom = () =>
  ({
    id: 0,
    classroomName: ''
  }) as Classroom;

export const useClassroomStore = defineStore('classroom', () => {
  const currentClassroom = ref<Classroom>(createDefaultClassroom());

  const get = (): Readonly<Classroom> => currentClassroom.value;
  const set = (newCurrentClassroom: Classroom): void => {
    currentClassroom.value = newCurrentClassroom;
  };

  return { get, set };
});
