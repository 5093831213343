import { render, staticRenderFns } from "./UserLogin.vue?vue&type=template&id=262ed238&scoped=true"
import script from "./UserLogin.vue?vue&type=script&setup=true&lang=ts"
export * from "./UserLogin.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./UserLogin.vue?vue&type=style&index=0&id=262ed238&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.23.7__cache-loader@4.1.0_webpack@4_kppdv2fqtl7l3c73fdr2wo4zi4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "262ed238",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseCard: require('/builds/mates-pay/app/new_reco/reco_for_teacher/src/shared/components/BaseCard.vue').default,ClassroomChangeDialog: require('/builds/mates-pay/app/new_reco/reco_for_teacher/src/domain/fragments/user/account/ClassroomChangeDialog.vue').default})
