import { render, staticRenderFns } from "./no_header.vue?vue&type=template&id=9ac06b42"
import script from "./no_header.vue?vue&type=script&lang=ts&setup=true"
export * from "./no_header.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./no_header.vue?vue&type=style&index=0&id=9ac06b42&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.23.7__cache-loader@4.1.0_webpack@4_kppdv2fqtl7l3c73fdr2wo4zi4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/builds/mates-pay/app/new_reco/reco_for_teacher/src/shared/components/Loading.vue').default,NotificationSnackBar: require('/builds/mates-pay/app/new_reco/reco_for_teacher/src/shared/components/NotificationSnackBar.vue').default,ErrorDialog: require('/builds/mates-pay/app/new_reco/reco_for_teacher/src/shared/components/dialogs/ErrorDialog.vue').default})
